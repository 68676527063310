@import '~@styles/variables.scss';

.competence-groups-sidebar {
  $this: &;

  display: flex;
  flex-direction: column;
  margin: 0 20px;
  margin-right: 0;
  margin-top: 36px;

  &__courses,
  &__filters {
    display: flex;
    flex-direction: column;
  }

  &__tabs {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    &__tab {
      flex: 0 0 auto;
      padding: 7px 18px;
      background: $color-grey-85;
      font-size: $font-size-sm;
      cursor: pointer;

      &:hover {
        border: 1px solid var(--color-main-dark);
        padding: 6px 17px;
      }

      &.active {
        background: var(--color-main-dark);
        color: $color-white;
      }
    }
  }

  &__item-category {
    margin-bottom: 3px;
    font-weight: 600;
  }

  &__divider {
    margin-top: 20px;
    border-top: 1px solid #ccc;
  }

  &__subtitle {
    color: #6e6e6e;
    font-size: $font-size-m;
  }

  &__item {
    color: #6e6e6e;
    font-size: $font-size-sm;
    cursor: pointer;
    font-weight: 400;
    padding: 10px;
    padding-left: 20px;
    input[type='checkbox'],
    input[type='radio'] {
      display: none;
    }
    &.active {
      background-color: #eeeeee;
    }
    .dott {
      width: 10px;
    }

    .label {
      display: inline-block;
      width: 80%;
      line-height: 120%;
    }

    input[type='checkbox'] + div {
      position: relative;
      top: -0.1em;
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: 4px;
      border: 1px solid $color-grey-40;
      background: $color-white;
      cursor: pointer;
      vertical-align: middle;
    }

    input[type='radio']:checked + div {
      background: var(--color-main-dark);
    }

    input[type='checkbox']:checked + div {
      background: var(--color-main-dark);
    }
  }

  &__buttons-disabled {
    .competence-groups-sidebar__item {
      opacity: 0.6;
      cursor: default;

      .dott {
        cursor: default;
      }
    }

    .competence-groups-sidebar__subtitle {
      color: $color-grey-60;
    }
  }

  &--mobile {
    margin-left: 7px;
    margin-right: 20px;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
    $active-border-color: $color-grey-70;
    $active-background-color: $color-grey-90;
    border-right: none;

    &__category_filter_button {
      &:hover {
      }
    }

    .courses-menu {
      flex: 0 0;
      align-items: center;
      width: auto;

      &__sorting-wrapper {
        margin: 0;
      }

      &__buttons-wrapper {
        height: 100%;
        margin: 0;
      }

      &__sorting-wrapper {
        height: 100%;
      }

      .sorting-active {
        align-items: center;

        .sorting-options {
          margin-top: -1px;
        }
      }
    }

    #{$this} {
      &__tabs {
        display: block;
        flex: 0 0 auto;
        margin-bottom: 20px;
        margin-left: 20px;

        &__tab {
          float: left;
          margin-right: 15px;
          padding: 6px 6px;
          background: transparent;
          color: $color-grey-30;

          &:hover {
            border: 0;
            border-bottom: 1px solid $color-black;
            padding-bottom: 5px;
          }

          &.active {
            border: none;
            border-bottom: 2px solid $color-black;
            padding-bottom: 4px;
            background: transparent;
            color: $color-black;
            font-weight: 500;
          }
        }
      }

      &__buttons {
        display: flex;
        flex: 0 0 auto;
        flex-direction: row;
        margin-top: -6px;
        margin-right: 31px;
        margin-bottom: 22px;
        margin-left: 25px;
      }

      &__item {
        width: 50%;
        min-width: 150px;
        font-weight: 400;
      }

      &__divider {
        width: 100%;
        margin-top: 10px;
      }

      &__filters-toggle {
        z-index: 2;
        flex: 0 0;
        width: 133px;
        height: 100%;
        border: 1px solid transparent;
        padding: 0 20px;
        color: $color-grey-30;
        font-size: $font-size-sm;
        white-space: nowrap;
        cursor: pointer;

        i {
          margin-left: 10px;
          color: $color-grey-40;
          font-size: $font-size-s;
        }

        &--active {
          border: 1px solid $active-border-color;
          border-radius: 0;
          border-bottom: 1px solid $active-background-color;
          background: $active-background-color;
        }
      }

      &__filters {
        z-index: 1;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        min-width: 131px;
        margin-top: -21px;
        padding: 10px 0;

        &--active {
          margin-bottom: 20px;
        }
      }

      &__courses {
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }

  &__competences-search {
    width: 100%;
    padding: 0;
    padding-right: 20px;
    margin-bottom: 30px;
    .search-bar__label {
      box-shadow: none;
      border: $separator-border-dark;
    }

    .search-bar__input {
      padding: 5px 10px;
      font-size: $font-size-sm;
    }
  }

  &__clear-search {
    margin-top: 10px;
    color: #6e6e6e;
    font-weight: bold;
    font-size: $font-size-sm;
    text-align: left;
  }
}
