@import '~@styles/variables.scss';

.mobile-report {
  display: flex;
  flex-direction: column;

  &__main-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
    padding: 0 7px;
  }

  &__select {
    flex-shrink: 1;
    flex-wrap: nowrap;
    width: 100%;
    margin: 0;

    &-button {
      display: flex;
      justify-content: space-between;
      font-size: $font-size-l;
    }
  }

  &__side {
    flex: 0 0;
    width: 60px;
    height: 100%;
    margin: 0;
    margin-right: 7px;
    margin-left: 7px;

    &-button {
      width: 100%;
      min-width: 0;
      font-size: $font-size-l;
    }
  }

  &__button {
    &-wrapper {
      margin: 0;
      padding: 15px 8px;
      line-height: inherit;
    }
  }

  &__options {
    position: absolute;
    overflow: hidden;
    left: 7px;
    right: 7px;
    margin: 50px 7px 5px 7px;
    border: 1px solid var(--color-main-dark);
    border-radius: 7px;
  }

  &__option {
    box-shadow: none;
    border-radius: 0;
    line-height: inherit;

    &-title {
      box-shadow: 0 1px 2px 0 var(--color-main-dark);
      margin: 0;
      border-radius: 0;
      padding: 10px;
      padding-left: 14px;
      border-top: 1px solid var(--color-main-dark);
      background: $color-white;
      color: var(--color-main-dark);
      font-weight: bold;

      :first-child {
        border-top: none;
      }
    }

    &-content {
      box-shadow: 0 1px 2px 0 var(--color-main-dark);
      margin: 0;
      border-radius: 0;
      padding-left: 10px;
      background: $color-white;

      > div {
        color: var(--color-main-dark);
        font-weight: normal;
      }
    }
  }
}
