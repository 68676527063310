.person-employment-form {
  display: flex;
  flex-direction: column;
  &__submit-button-wrapper {
    display: block;
    margin-top: 20px;
    padding-right: 5px;
    text-align: right;
    margin-bottom: 20px;
    margin-left: auto;
    display: flex;
  }
}
