@import '~@styles/variables.scss';

.indicator-dots {
  $dot-size: 12px;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: absolute;

  &__wrapper {
    position: absolute;
    bottom: -40px;
    left: 0;
    right: 0;
    height: 40px;
    display: flex;
    justify-content: space-between;
    min-width: 100px;
    max-width: 200px;
    margin: 0 auto;
  }

  &__arrows {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 160px;
    display: flex;
    justify-content: space-between;
  }

  &__dot {
    width: $dot-size;
    height: $dot-size;
    border: 1px solid $color-grey-70;
    border-radius: 100%;
    background: $color-grey-90;
    cursor: pointer;
    margin: 0 4px;

    &--active {
      border: 1px solid $color-grey-90;
      background: $color-white;
    }

    &__arrow {
      flex: 0 0 auto;
      padding: 4px 8px;
      font-size: 52px;
      vertical-align: middle;
      cursor: pointer;
      opacity: 0.3;

      @media screen and (max-width: $tablet-width) {
        font-size: 42px;
        margin: 0 -10px;
      }
    }
  }
}
