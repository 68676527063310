@import '~@styles/variables.scss';

.course-date {
  position: relative;
  display: flex;
  width: 100%;

  &__date {
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-self: center;
    align-self: center;
    min-width: 50px;
    margin-right: 20px;
    border: 1px solid black;
    border-radius: 6px;
    padding: 6px;
    line-height: 1.2;

    &-day {
      font-size: $font-size-ml;
      font-weight: 600;
    }

    &-month {
      font-size: $font-size-s;
    }
  }

  &__details {}

  &__title {
    margin-bottom: 5px;
    font-size: $font-size-sm;
    font-weight: 600;
  }

  &__location,
  &__hours {
    font-size: $font-size-s;
  }

  &__button {
    align-self: center;
    margin-right: 0;
    margin-left: auto;
  }
}
