@import '~@styles/variables.scss';

.employees-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 200px;

  &__table{
    width: 100%;
    border-collapse: collapse;
    th{
      font-size: $font-size-sm;
      font-weight: 500;
      text-align: left;
    }
    td{
      padding: 10px 0 ;
      &:first-child{
        width: 70px;
      }
    }
  }

  &__card-wrapper {
    overflow-x: hidden;
    display: flex;
    flex: 100%;

    &.absent{
      opacity: 0.4;
    }
    @media screen and (max-width: $desktop-medium-width) {
      flex: 0 0 50%;
    }

    @media screen and (max-width: $desktop-small-width) {
      flex: 0 0 100%;
      padding: 6px 10px;
    }

    @media screen and (max-width: $tablet-width) {
      flex: 0 0 50%;
    }

    @media screen and (max-width: $tablet-small-width) {
      flex: 0 0 100%;
    }
  }
}
