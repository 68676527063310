@import '~@styles/variables.scss';

.news-list {
  max-width: 1024px;
  margin: 0 auto;
  margin-top: 30px;
  .carousel {
    position: relative;
    height: 280px;
    margin-bottom: 100px;
    &__slide {
      position: relative;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: visible;
    }

    &__inner-slide {
      clear: both;
      overflow: visible;
      height: auto;
    }

    &__slider {
      position: absolute;
      top: 0;
      right: 52px;
      bottom: 0;
      left: 52px;

      @media screen and (max-width: $tablet-width) {
        right: 32px;
        left: 32px;
      }

      &-tray-wrapper {
        margin-right: -2px;
      }
    }

    &__dot-group {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    @media screen and (max-width: $mobile-width) {
      margin: 0 -10px;
    }
  }

  &__title {
    margin: 20px 0 30px;
    margin-left: 10px;
    font-weight: 400;
  }

  &__news-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  &__item {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: left;
    width: auto;
    margin: 0 auto;
    cursor: default;
    $this: &;
    &--clickable {
      cursor: pointer;
    }

    #{$this}--center & {
      align-items: center;
    }

    &-wrapper {
      position: relative;
      right: 0;
      left: 0;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 0 auto;
    }

    &-image {
      flex: 1 1 auto;
      width: auto;
      max-width: 100%;
      height: auto;
      max-height: 100%;
      margin: auto;
      margin-bottom: 0;
      margin-left: 0;
      border: 8px solid #fff;
      object-fit: scale-down;

      #{$this}--center & {
        margin-left: auto;
      }

      &__wrapper {
        position: relative;
        overflow: hidden;
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        width: 200px;
        height: 150px;
        #{$this}--center & {
          margin: 0 auto;
        }

        #{$this}--small & {
          height: auto;
          max-height: 126px;
        }
      }
      &__content{

          width: 100%;
          align-self: stretch;
          width: 100%;
          height: 100%;
          background-size: cover;

      }
    }

    &-title {
      flex: 0 1 auto;
      max-width: 250px;
      line-height: 1.35;
      margin: 0 auto;
      margin-top: 10px;
      position: absolute;
      bottom: 0px;
      background-color: rgba(0, 0, 0, .5);
      width: 100%;
      text-align: left;
      padding: 10px;
      color: white;
      min-height: 60px;
      font-size: 0.9rem;
      font-weight: 400;

    }

    &-redirect-btn {
      flex: 0 1 auto;
      margin-right: auto;
      margin-left: 0;
      box-shadow: none;
      border-radius: 10px;
      background: none !important;
      color: white;
      font-weight: 700;
      font-size: 0.8rem;
      line-height: 27px!important;
      cursor: pointer;
      border: 1px solid #ddd;
      height: 100%;
      width: 100%;
      background: none;
      border-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      height: 42px;
      text-align: left;
      a {
        background: none
      }
    }

    &--active {
      #{$this}-image {
        border-color: var(--color-main-dark);
      }

      #{$this}-title {
        font-weight: 600;
      }
    }
  }

  .indicator-dots {
    margin-bottom: 50px;
  }
}
