@import '~@styles/variables.scss';

.frame {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 550px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.75), 0 8px 30px rgba(0, 0, 0, 0.5);
  margin: 2em;
  border-radius: 2em;
  padding-top: 2em;
  color: white;

  &::before {
    content: '';
    z-index: 10;
    display: block;
    width: 4em;
    height: 0.6em;
    margin: 0 auto;
    border-radius: 10em;
    background: white;
    mix-blend-mode: overlay;
  }

  //-----Background
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 2em;
    pointer-events: 0;

    .bg-color,
    .bg-black {
      position: absolute;
      width: 200%;
      border-radius: 100em;
      animation: rotate 100s linear infinite;
    }

    .bg-color {
      top: 0;
      right: 0;
      z-index: 10;
      filter: blur(40px);
      background-image:
        linear-gradient(
          -45deg,
          #35c3f3 0%,
          #8b9fe8 20%,
          #e681d8 39%,
          #ffa9a4 76%,
          #fed2ce 100%
        );
      mix-blend-mode: luminosity;
    }

    .bg-black {
      bottom: 0;
      left: 0;
      filter: blur(40px);
      animation-delay: 100s;
      animation-direction: reverse;
      z-index: 1;
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.competence_overview {
  width: 55%;

  .header {
    margin-top: 0;
    font-weight: bold;
    font-size: 1.2em;
  }

  .progress-circle {
    align-self: center;
    width: 100%;
    font-size: 40px;
  }
}

.employee-preview {
  display: flex;
  flex-direction: row;

  &__card-wrapper {
    float: left;
    clear: both;
    flex: 0 0 300px;
    width: 275px;

    .employee-card {
      margin-right: 20px;
      padding: 30px 20px;
    }
  }

  &__competences-wrapper {
    flex: 1 1 0;
    margin-left: 3em;
    padding-left: 300px;

    .events-list__item {
      width: 100%;
    }
  }

  &__competences-header {
    display: flex;
    margin: 10px;
  }

  &__competences-header-title {
    flex-grow: 1;
    margin: 0;
  }

  &__tasks {
    float: left;
    clear: both;
    width: 100%;
  }

  &__competences-header-actions {
    position: relative;
    display: flex;
  }

  &__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center;
  }

  &__add-menu {
    position: absolute;
    top: 40px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 150px;
    box-shadow: $card-shadow;
    border: 2px solid $color-grey-80;
    border-radius: 8px;
    background: white;
  }

  &__add-menu-item {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    border-bottom: none;
    padding: 18px 24px;
    padding-left: 8px;
    background: none;
    color: $color-black;
    font-weight: 500;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: $color-grey-95;
    }

    &:disabled {
      opacity: 0.2;
    }
  }

  &__roles-modal-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(77, 77, 77, 0.7);
  }

  &__roles-modal {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 400px;
    min-height: 300px;
    margin: auto;
    border-radius: 8px;
    padding: 15px;
    background: white;
  }

  &__roles-modal-close-button {
    position: absolute;
    top: 17px;
    right: 17px;
  }

  &__roles-modal-header {
    margin: 5px 0;
  }

  @media only screen and (max-width: $tablet-width) {
    flex-direction: column;

    &__card-wrapper {
      float: none;
      clear: none;
      display: flex;
      order: 2;
    }

    &__competences-wrapper {
      width: 100%;
      margin-top: 30px;
      padding-left: 0;
    }

    .employee-card {
      margin-right: auto;
      margin-bottom: 20px;
    }
  }
}
