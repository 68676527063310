@import '~@styles/variables.scss';

.messages-header{
  font-weight: 500;
  color: black;
  font-size: 2.15rem;
  margin-bottom: 3rem;
}

.my-education-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  padding: 0 20px;
  position: relative;

  &.nopadding{
    padding: 0;
  }
  &.fullheight{
    height: 100vh;
  }

  &.fillviewport-x{
    width: 100vw;
  }

  &__section {
    width: 100%;
    margin: 15px 0 0;
    transition: flex 0.3s ease-out;

    &.nomargin{
      margin: 0;
    }

    &.fullheight{
      height: 100%;
    }

    .messages{
      @include lg() {
        padding-left: 5em;
      }
    }

  }

  &__section-option {
    margin: 0;
    padding: 0;
  }

  @media screen and (max-width: $tablet-width) {
    &__section {
      width: 100%;
      margin: 5px 0 0;
    }
  }
}
