@import '~@styles/variables.scss';

.course-calendar-card,
.course-calendar-card__loading {
  position: relative;
  display: flex;
  flex: 1 1 auto;

  &__date {
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    -webkit-align-self: center;
    align-self: center;
    min-width: 50px;
    margin-right: 20px;
    border: 1px solid black;
    border-radius: 6px;
    padding: 6px;
    line-height: 1.2;

    &__day {
      font-size: $font-size-ml;
      font-weight: 600;
    }

    &__daytext {
      font-size: $font-size-s;
    }
  }

  &__dropdown {
    z-index: 10;
  }

  .course-sign {
    align-self: flex-start;
    margin-right: auto;
    margin-left: 0;
  }

  &__card {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;

    a {
      color: inherit;
    }
  }

  &__text {
    display: block;
    flex: 1 1;
    flex-basis: 270px;
    color: $color-grey-40;
    font-size: $font-size-sm;
    white-space: normal;

    &__title {
      font-weight: 700;
    }

    &__details {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 5px;

      .course-calendar-card__detail {
        margin-right: 30px;

        i {
          margin-right: 5px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__description {
      margin-top: 20px;
    }
  }

  &__detail {
    flex: 1 1 150px;

    &:first-child {
      flex-grow: 0;
      flex-basis: content;
    }
  }
}

.course-calendar-card__loading {
  svg {
    width: 450px;
    height: 104px;
  }
}
