@import '~@styles/variables.scss';
@import '~@routes/reports/variables.scss';

.extra-chart {
  position: relative;
  overflow: visible;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0;
  margin: 0 20px;

  @media screen and (max-width: $large-screen) {
    margin-bottom: 30px;
  }

  &__title {
    flex: 0 0 auto;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    color: $font-color-light;
    font-weight: 700;
    font-size: 1.8rem;
  }

  &__subtitle {
    position: relative;
    flex: 0 0 auto;
    margin: 0 auto;
    color: $color-grey-60;
    font-size: $font-size-m;
  }

  &__canvas-wrapper {
    position: relative;
    flex: 1 1 100%;
    width: 100%;
    margin: 0;

    svg {
      position: absolute;
      flex: 1 1 100%;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      margin: 0;
    }
  }

  &__progress {
    position: relative;
    display: block;
    display: flex;
    flex: 1 1 auto;

    &-main {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #6a6868;
      font-weight: 700;
      font-size: 36px;
      line-height: 0;
      transform: translateX(-50%);
      vertical-align: middle;
    }

    @media screen and (max-width: $large-screen) {
      &-main {
        font-size: 26px;
      }
    }
  }
}
