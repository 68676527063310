@import '~@styles/variables.scss';

.form-select {
  position: relative;
  padding: $form-field-wrapper-padding;

  &__select {
    position: relative;
    height: 40px;
    border-radius: 10px;
    background: inherit;
  }

  &__icon {
    position: absolute;
    right: 20px;
    bottom: 15px;
    color: $color-grey-60;
  }
}
