@import '~@styles/variables.scss';

.search-bar {
  $this: &;

  position: relative;
  display: flex;
  padding-right: 1em;

  &-open {
    #{$this} {
      &__label {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__label {
    position: relative;
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 8px;
    cursor: text;
  }

  &__label-text {
    margin-bottom: 7px;
    margin-left: 5px;
  }

  &__input {
    flex: 1 1 auto;
    border: none;
    padding: 6px;
    padding-left: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;

    &-inset {
      padding-left: 50px !important;
      font-weight: 700;
    }
  }

  &__avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 8px;
    display: flex;
    align-items: center;
  }

  &__icon {
    position: absolute;
    top: 50%;
    right: 20px;
    display: inline;
    height: 100%;
    margin: auto 0;
    color: $color-grey-80;
    line-height: 0;
    pointer-events: none;

    i {
      line-height: 0;
    }

    &__loading {
      color: $color-black;
    }
  }

  &__open {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &__dropdown {
    display: block;
    flex: 1 1 auto;
    flex-direction: column;
    box-shadow: $card-small-shadow;
    border-radius: 8px;
    border-top: none;
    background-color: $color-white;

    &__avatar {
      position: relative;
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      height: 100%;
      margin-right: 18px;
    }

    &__wrapper {
      display: flex;
      margin: -10px 0;
      margin-top: -12px;
      padding: 10px;
    }

    &__item {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      margin: auto 0;
      border-bottom: $card-separator;
      padding: 10px 24px;
      font-size: $font-size-sm;
      line-height: 1.4;

      &.item-link {
        cursor: pointer;

        &:hover {
          background-color: $color-grey-95;

          .person-avatar {
            box-shadow: 1px 1px 2px rgba(100, 100, 100, 0.2);
          }
        }
      }

      &:last-child {
        border-bottom: none;
      }

      &__active {
        font-weight: 700;
      }
    }
  }
}
