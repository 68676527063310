@import '~@styles/variables.scss';

.certificate {
  position: relative;
  padding: 8px 12px;
  font-size: 1.4rem;
  line-height: 1;

  &,
  &:visited {
    color: $color-pdf;
  }

  & &__tooltip {
    position: absolute;
    right: 100%;
    bottom: -1.1rem;
    bottom: initial;
    bottom: 0;
    left: initial;
    display: none;
    padding: 8px 0;
    font-size: 0.9rem;
    line-height: 1.4rem;
    text-align: center;
    white-space: nowrap;
  }

  &:hover {
    color: darken($color-pdf, 12%);

    .certificate__tooltip {
      display: block;
    }
  }
}

.competence--expanded .certificate .certificate__tooltip {
  display: block;
}
