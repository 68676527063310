@import '~@styles/variables.scss';

.course-catalog-switch {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .breadcrumbs {
    max-width: 1024px;
    padding: 0;
  }

  &__section {
    width: 100%;
    transition: flex 0.3s ease-out;
  }

  &__section.route_course-catalog {
    height: 100%;
    overflow-x: hidden;
  }

  @media only screen and (max-width: $tablet-width) {
    &__section {
      width: 100%;
      margin: 0;
    }
  }
}
