@import '~@styles/variables.scss';

.course-dates {
  width: 100%;

  &__item {
    display: flex;
    border: 1px solid #CCC;
    margin-top: 25px;
    border-radius: 4px;
    padding: 0.9em;
  }
}
