@import '~@styles/variables.scss';

.course-duration {
  -ms-flex-align: baseline;
  align-items: baseline;
  color: $color-grey-30;
  font-weight: bold;
  font-size: 1rem;

  &__clock {
    margin-right: 5px;
  }
}
