@import '~@styles/variables.scss';

.person-competence-form {
  display: flex;
  flex-direction: column;

  &__category-button {
    width: 100%;
    height: 100%;
    padding: 8px;
    padding-left: 15px;
    text-align: left;
    cursor: pointer;
  }

  &__input-wrapper {
    width: 100%;
  }

  &__section-wrapper {
    position: relative;
    overflow-y: auto;
    flex: 1 1 auto;
    width: 100%;
    min-height: 80px;
    max-height: 60vh;
    margin: 0;
    padding-right: 20px;
  }

  &__section {
    margin-top: 10px;
    border: 1px solid #efefef;
    border-radius: 5px;
    font-weight: 600;
    font-size: $font-size-m;

    &.active {
      background-color: #efefef;

      .person-competence-form__category-button {
        font-weight: 900;
      }
    }
  }

  &__input {
    width: 100%;
  }

  &__submit-button-wrapper {
    display: block;
    flex: 0 1 auto;
    width: 100%;
    margin-top: 20px;
    padding-right: 5px;
    text-align: center;
  }
}
