@import '~@styles/variables.scss';

.employees-checklist {
  flex: 1 1 auto;
  padding: 10px;

  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    color: $font-color;
  }

  &__avatar {
    flex: 0 0 40px;
    margin: auto 0;
    margin-left: 10px;

    .person-avatar {
      width: 45px;
      height: 45px;
      margin: auto;
    }
  }

  &__dott {
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
    margin: 8px 20px;
    border: 2px solid $color-grey-60;
    border-radius: 50%;
    background: $color-white;

    &-disabled {
      opacity: 0.5;
    }

    &:hover {
      background: $color-grey-95;
    }
  }

  &__dott-done {
    flex: 0 0 28px;
    width: 28px;
    height: 28px;
    margin: 8px 20px;
    border: 2px solid #006200;
    border-radius: 50%;
    background: #6dc995;

    i {
      padding-left: 6px;
      color: #2e5b41;
      font-size: $font-size-s;
    }
  }

  &__card {
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    box-shadow: $card-small-shadow;
    border-radius: 5px;
    padding: 10px;
    background-color: $color-white;
  }

  &__text {
    margin: auto 0;

    &__name {
      color: var(--color-main-dark);
      font-weight: 700;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
