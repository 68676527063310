@import '~@styles/variables.scss';

.profile-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;

  &__section {
    width: 100%;
    margin: 15px 0 0;
  }

  &__card {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    border-radius: 8px;
    flex-direction: column;
    background: white;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;

  }

  &__profile-picture-wrapper {
    min-width: 100px;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }

  &__person-form-wrapper {
    flex-basis: 80%;
    min-width: 500px;
  }

  &__tabs-wrapper {
    margin: 10px;
  }

  &__profile-picture {
    $picture-size: 100px;

    align-self: center;
    width: $picture-size;
    height: $picture-size;
    margin: 10px 4px;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    label{
      font-weight: 500;
    }
  }

  &__add-profile-picture-label {
    align-self: center;
    margin: 0 4px;
    color: $font-color-light;
    font-size: 0.7em;
    cursor: pointer;
  }

  @media only screen and (max-width: $tablet-width) {
    &__tabs-wrapper {
      margin: 10px 0 0;
    }

    &__card {
      justify-content: center;
    }

    &__profile-picture-container {
      width: 100%;
      margin-left: 0;
    }

    &__person-form-wrapper {
      flex-basis: 100%;
      min-width: inherit;
    }
  }
}
