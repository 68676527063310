@import '~@styles/variables.scss';

.course-card,
.course-card-loading {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  width: 100%;

  &__cover {
    position: relative;

    &--image {
      width: 100%;
      height: 100%;
      border: 10px solid $color-white;
    }
  }

  &__content {
    padding: 0 10px;
  }
}

.course-card-loading {
  .course-card__content {
    overflow: hidden !important;
    flex: 1 1 100%;

    svg {
      flex: 0 0 auto;
      width: 100%;
      height: 150px;
    }
  }
}
