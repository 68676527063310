@import '~@styles/variables.scss';

.employees-page {
  align-items: center;
  width: 96%;
  margin: 0 2%;
  margin-top: 40px;
  margin-bottom: 80px;
  @include lg(){
    & > *:not(.fullwidth) {
      width: 80%;
      max-width: 1280px;
      margin: 0 auto;
    }
  }

  .maintitle-link {
    display: block;
    color: black;
  }

  h1 {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: $font-size-xl;
  }

  h2 {
    margin-top: 0;
    font-weight: 600;
    font-size: 1.2rem;
  }

  .organisation-header-wrapper {
    display: flex;
  }

  .organisation-treeview-wrapper {
    display: flex;
    width: 100%;
  }

  .organisation-header {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    margin-bottom: 20px;

    .organisation-drop {
      position: relative;
      h1 {
        position: relative;
        float: left;
        cursor: pointer;
        margin: 0;
        padding: 0;
        padding-right: 40px;

        .open-sub {
          position: absolute;
          top: -2px;
          right: 0;
          width: 20px;
        }
      }
    }

    h1 {
      width: auto;
    }

    .open-tree {
      align-self: flex-end;
      cursor: pointer;
      color: #1971c2;
      align-self: center;
      margin-left: 50px;
      font-size: $font-size-s;
    }

    .sub-menu {
      position: absolute;
      z-index: 2000;
      width: 300px;
      margin-left: -9px;
      margin-top: 0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding-top: 4px;
      padding-bottom: 4px;
      background-color: white;
      margin-top: $font-size-l*2;
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: none;
          font-size: $font-size-sm;
          cursor: pointer;

          padding: 6px 0;
          text-indent: 10px;
          &:hover {
            background-color: #ECF2FC;
            color: #0B5CD7;
          }
        }
      }
    }
  }

  &__left-wrapper {
    flex: 0 0 33.33333%;

    h2 {
      margin: 8px;
      font-weight: 700;
      font-size: $font-size-m;
      text-transform: initial;
    }
  }

  &__section {
    margin-top: 20px;

    $left-column-width: 275px;
    $left-column-width-and-margin: 310px;

    &--message {
      display: flex;
      justify-content: space-between;
    }

    &--columns {
      .employee-card,
      .employee-card__loader--expanded {
        float: left;
        width: $left-column-width;
        max-width: $left-column-width;
        min-height: 375px;
      }
    }
  }

  &__users-access-wrapper {
    button {
      width: 100%;
      cursor: pointer;
    }

    ul {
      margin-left: 20px;
      padding: 0;
      list-style: none;
      text-align: left;

      li {
        button {
          text-align: left;
        }
      }
    }
  }

  &__report-wrapper {
    margin-left: 20px;
  }

  &__grid-wrapper {
    display: flex;
    flex-direction: row;
  }

  .statistic-card-first { margin-left: 0; }
  .statistic-card-last { margin-right: 0; }

  &__statistics {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 3em;
    padding-bottom: 2em;

    &--mobile {
      margin-bottom: 20px;

      .statistic-card {
        position: relative;
        box-shadow: none;
        margin: 0 15px;
        margin-bottom: 15px;
        border-radius: 0;
        padding: 0;
        background: transparent;

        .progress {
          width: 100%;
          font-size: $font-size-s;
        }
      }

      .employees-page__statistics-card-wrapper {
        flex: 1 1 100%;
      }
    }

    &-mobile-title {
      width: 100%;
      margin-bottom: 0;
      padding-left: 10px;
      font-weight: 500;
      font-size: $font-size-l;
    }
  }

  &__statistics-wrapper {
    flex: 0 0 66.66666%;
  }

  &__statistics-card-wrapper {
    display: flex;
    flex: 0 0 33.333333%;
    align-items: stretch;
    &.items2 {
      flex: 0 0 50%;
    }
    &.items4 {
      flex: 0 0 25%;
    }
    &.items5 {
      flex: 0 0 20%;
    }
    @media screen and (max-width: $desktop-small-width) {
      flex: 0 0 50%;
    }
  }

  &__activity-and-users-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 3px;
  }

  &__employee-preview-page-wrapper {
    width: 80%;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 4em;
  }

  &__activity-wrapper {
    display: flex;
    margin-top: 2em;
    margin-bottom: 60px;
  }

  &__users-overview-wrapper {
    display: flex;
    flex: 1 1 33.33333%;

    > * {
      margin: 7px;
    }
  }

  &__status-message {
    margin: 10px;
    color: var(--color-main-dark);
    font-weight: 700;
    text-align: center;
  }

  &__mobile-checklist {
    margin-top: 20px;
  }

  &__mobile-checklist,
  &__mobile-personnel {
    width: 100%;
    margin-bottom: 20px;
  }

  &__mobile-toggle {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 17px);
    box-shadow: $card-shadow;
    margin-left: 7px;
    border-radius: 10px;
    padding: 15px 17px;
    background: $color-white;
    color: var(--color-main-dark);
    font-weight: 400;
    font-size: $font-size-l;
    text-align: left;
    cursor: pointer;
  }

  @media only screen and (max-width: $tablet-width) {
    width: 96%;
    margin-top: 0;

    &__activity-wrapper {
      margin-bottom: 0;
    }

    &__grid-wrapper {
      flex-direction: column-reverse;
    }

    &__statistics-wrapper {
      flex: 0 0 auto;
    }

    &__left-wrapper {
      flex: 0 0 auto;
      margin-bottom: 25px;
    }

    &__report-wrapper {
      margin-left: 0;
    }

    &__section {
      &--columns {
        flex-direction: column;
      }

      &--edit {
        display: flex;
        align-items: center;

        .employee-edit__card {
          float: none;
          margin: 15px 10px;
        }
      }

      &--message {
        flex-direction: column;
        align-items: center;
      }

      &--preview {
        display: flex;
        align-items: center;
      }
    }
  }
}
