@import '~@styles/variables.scss';
.my-education {
  &__bg {
    background-color: #F6F5FA;
    height: 350px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    display:none;
    @include md() {
      display:block;
    }
  }
  &__section {
    padding-top: 10px;
  }
}
.course-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  &__status {
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: $font-size-m;
  }

  &__content {
    width: 100%;
    margin-bottom: 30px;
  }

  &__module {
    padding: 20px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    > * {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 100px;
      }
    }
  }

  &__cover {
    display: flex;
    height: 400px;
    border-radius: 10px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  &__header-title-wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
  }

  &__header-title {
    overflow: hidden;
    display: flex;
    color: black;
    font-weight: 700;
    font-size: $font-size-xl;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__header-meta-container {
    display: flex;
  }

  &__header-meta-item {
    display: flex;
    align-items: baseline;
    color: $color-grey-50;
    font-weight: bold;
    font-size: $font-size-s;
    line-height: 1.5;

    &:not(:first-child) {
      margin-left: 30px;
    }

    i {
      margin-right: 10px;
    }
  }

  &__description {
    line-height: 1.2;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &__action-button {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
