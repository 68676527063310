@import '~@styles/variables.scss';
@import '~@routes/reports/variables.scss';

.highscore-list {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
  align-self: flex-start;
  max-width: 500px;
  margin: 0 auto;

  @media screen and (max-width: $medium-screen) {
    margin-bottom: 40px;
  }

  &__title {
    align-items: baseline;
    margin: 0;
    margin-bottom: 0;
    color: $font-color-light;
    font-weight: 700;
    font-size: $font-size-xl;

    &__wrapper {
      overflow: visible;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      height: 1em;
      margin: 14px 0;
      margin-left: 8px;
      color: $font-color-light;
      font-weight: 700;
      font-size: $font-size-xl;
    }
  }

  &__content {
    flex: 1 1 auto;
    flex-direction: column;
    box-shadow: $card-small-shadow;
    border: $card-border;
    border-radius: 3px;
    background-color: $color-white;
    will-change: transform;

    &__item {
      position: relative;
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      margin: 0;
      margin-left: 4px;
      font-size: $font-size-sm;

      &__labels {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        > * {
          overflow: hidden;
          width: 100%;
          line-height: 1.4;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:first-child {
            margin-top: auto;
          }

          &:last-child {
            margin-bottom: auto;
          }
        }
      }

      &__wrapper {
        display: flex;
        flex-direction: row;
        border-bottom: $card-separator;
        padding: 8px 14px;

        &:last-child {
          border-bottom: 0;
        }
      }

      &__avatar {
        flex: 0 0 28%;
        align-self: center;
        padding: 3px 0;

        .person-avatar {
          width: 55px;
          height: 55px;
          margin: auto;
        }
      }

      &__name,
      &__score {
        font-weight: 600;
      }

      &__score {
        color: $color-points;
      }

      &__details {
        color: $color-grey-70;
      }
    }
  }
}
