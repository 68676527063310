@import '~@styles/variables.scss';
@import '~@styles/functions.scss';

.course-player {
  display: flex;
  width: 100%;
  height: 100%;

  .lms-dialog {
    flex: 1 1 auto;
    max-width: 700px;
    margin: auto;
    margin-top: 0;
    border-radius: 8px;
    padding: 40px;
    background: white;

    &--opened {
      max-width: 400px;
      text-align: center;
    }

    button {
      margin-top: 20px;
    }
  }

  iframe {
    flex: 0 0;
    width: 90%;
    height: 90%;
    margin: 0;
    padding: 0;
  }
}
