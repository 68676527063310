@import '~@styles/variables.scss';


.career-path {
  $this: &;
  $time-period-height: 40px;

  display: flex;
  flex-direction: column;

  &__time-periods {
    overflow-y: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__time-period {
    position: relative;
    display: flex;
    flex-shrink: 0;
    height: $time-period-height;
    margin: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;
    padding: 0 15px 0 25px;
    background: white;
    font-weight: 500;
    line-height: $time-period-height;
    text-transform: uppercase;

    &:not(:last-child) {
      margin-right: 25px;
    }

    &--passed {
      border-color: $color-green;
      background: white;
      color: $color-green;
    }

    &--active {
      border-color: var(--color-main-dark);
      background: var(--color-main-dark);
      color: white;
    }

    &--locked {
      border-color: $color-grey-70;
      background: $color-grey-90;
      color: $color-grey-70;
    }
  }

  &__time-period-number {
    $time-period-number-size: 22px;

    position: absolute;
    top: ($time-period-height - $time-period-number-size - 2px) / 2;
    left: -($time-period-number-size / 2);
    width: $time-period-number-size;
    height: $time-period-number-size;
    border: 2px solid;
    border-color: inherit;
    border-radius: 50%;
    background: inherit;
    color: inherit;
    line-height: $time-period-number-size;
    text-align: center;

    #{$this}__time-period--passed & {
      background: green;
      color: white;
    }

    #{$this}__time-period--active & {
      border-color: white;
    }
  }

  &__competences {
    display: flex;
    flex-direction: column;
  }

  &__competence-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  &__competence-status {
    $status-size: 18px;

    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: $status-size;
    height: $status-size;
    margin-right: 20px;
    margin-left: 10px;
    border: 2px solid $color-grey-70;
    border-radius: 50%;
    background: white;
    font-size: 0.7 * $status-size;
    line-height: $status-size;

    &--passed {
      border-color: $color-green;
      background-color: lighten($color-green, 70);
      color: $color-green;

      i.fas.fa-check {
        transform: translateY(1px);
      }
    }

    &--close-to-deadline {
      border-color: $color-orange;
      background-color: lighten(orange, 45);
    }

    &--after-deadline {
      border-color: $color-red;
      background-color: lighten($color-red, 45);
    }
  }

  &__competence-card {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: flex-start;
    box-shadow: $card-shadow;
    border-radius: 5px;
    background: white;
  }

  &__competence-cover {
    $cover-size: 100px;

    display: flex;
    flex-shrink: 0;
    width: 140px;
    height: 140px;
    margin: 15px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__competence-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-self: stretch;
    margin: 15px;

    &:not(:first-child) {
      margin-left: 0;
    }
  }

  &__competence-title {
    display: flex;
    color: var(--color-main-dark);
    font-weight: 700;
    font-size: $font-size-l;
  }

  &__competence-description {
    display: flex;
    font-weight: 500;
    font-size: $font-size-m;
  }

  &__competence-actions {
    display: flex;
  }

  &__passed-competence-card {
    flex-grow: 1;
    border-radius: 5px;
    padding: 15px;
    background: $color-green;
    color: white;
    font-weight: 700;
    font-size: $font-size-l;
  }
}
